<template>
  <div class="">
    <div class="card">
      <div class="card-body">
        <h4
          class="card-title"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'txt-left'"
        >
          {{ $t("app.timesAndPrices") }}
        </h4>

        <div class="tab-danger pt-3">
          <div class="row">
            <!-- <div class="col-1"></div> -->
            <div class="form-group col-md-6 col-lg-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 1 {{ $t("app.hours") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_1hour"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-md-6 col-lg-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 2 {{ $t("app.hours") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_2hour"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-md-6 col-lg-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 3 {{ $t("app.hours") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_3hour"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-md-6 col-lg-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 4 {{ $t("app.hours") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_4hour"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-md-6 col-lg-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 5 {{ $t("app.hours") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_5hour"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-md-6 col-lg-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 6 {{ $t("app.hours") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_6hour"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-md-6 col-lg-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 7 {{ $t("app.hours") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_7hour"
                v-on:input="onFormChange(false)"
              />
            </div>
            <div class="form-group col-md-6 col-lg-3">
              <label
                :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'float-left'"
              >
                {{ $t("app.price") }} 8 {{ $t("app.hours") }}
              </label>
              <input
                type="number"
                class="form-control"
                v-model="row.price_8hour"
                v-on:input="onFormChange(false)"
              />
            </div>
          </div>

          <div><br /><br /></div>

          <!-- Nav Tabs -->
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                href="#tab-time1"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="true"
                :class="
                  $i18n.locale == 'ar'
                    ? 'hacen_algeria font-size-15 active show'
                    : ''
                "
              >
                1 {{ $t("app.hours") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time2"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                2 {{ $t("app.hours") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time3"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                3 {{ $t("app.hours") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time4"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                4 {{ $t("app.hours") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time5"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                5 {{ $t("app.hours") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time6"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                6 {{ $t("app.hours") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time7"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                7 {{ $t("app.hours") }}
              </a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-time8"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
                :class="
                  $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                "
              >
                8 {{ $t("app.hours") }}
              </a>
            </li>
          </ul>
          <!-- End Nav Tabs -->

          <!-- Tab Panels -->
          <div class="tab-content">
            <div
              role="tabpanel"
              class="tab-pane fade pt-3"
              :class="$i18n.locale == 'ar' ? 'active show' : ''"
              id="tab-time1"
            >
              <div class="col-12">
                <p><br /></p>
                <!-- <div 
                                      v-for="(time, idx) in $props.times_15min" 
                                      class="col-2 btn mr-2 mb-2"
                                      :class="(time['status'] ? 'btn-success' : 'btn-secondary')"
                                      @click="onFormChange(idx, 'times_15min'); (time['status']) ? false : true">
                                      {{ time['value'] }} - {{ idx }}
                                  </div> -->
                <div
                  v-for="(time, idx) in times_1hour"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_1hour')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time2"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_2hour"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_2hour')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time3"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_3hour"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_3hour')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time4"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_4hour"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_4hour')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time5"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_5hour"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_5hour')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time6"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_6hour"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_6hour')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time7"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_7hour"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_7hour')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>

            <div
              role="tabpanel"
              class="tab-pane fade in pt-3"
              :class="$i18n.locale == 'ar' ? '' : 'active show'"
              id="tab-time8"
            >
              <div class="col-12">
                <p><br /></p>
                <div
                  v-for="(time, idx) in times_8hour"
                  :key="idx"
                  class="col-2 btn mr-2 mb-2"
                  :class="$i18n.locale == 'ar' ? '' : 'float-left'"
                >
                  <input
                    class=""
                    type="checkbox"
                    :value="time['status']"
                    :checked="time['status']"
                    v-on:input="onFormChange(idx, 'times_8hour')"
                  />
                  <label
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    &nbsp; {{ time["value"] }} &nbsp;
                  </label>
                </div>
              </div>
            </div>
          </div>
          <!-- End Tab Panels -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "FormAvailableConvention",
  props: [
    "times_1hour",
    "times_2hour",
    "times_3hour",
    "times_4hour",
    "times_5hour",
    "times_6hour",
    "times_7hour",
    "times_8hour",

    "price_1hour",
    "price_2hour",
    "price_3hour",
    "price_4hour",
    "price_5hour",
    "price_6hour",
    "price_7hour",
    "price_8hour",
  ],
  data() {
    return {
      row: {
        times_1hour: this.$props.times_1hour,
        times_2hour: this.$props.times_2hour,
        times_3hour: this.$props.times_3hour,
        times_4hour: this.$props.times_4hour,
        times_5hour: this.$props.times_5hour,
        times_6hour: this.$props.times_6hour,
        times_7hour: this.$props.times_7hour,
        times_8hour: this.$props.times_8hour,

        price_1hour: this.$props.price_1hour,
        price_2hour: this.$props.price_2hour,
        price_3hour: this.$props.price_3hour,
        price_4hour: this.$props.price_4hour,
        price_5hour: this.$props.price_5hour,
        price_6hour: this.$props.price_6hour,
        price_7hour: this.$props.price_7hour,
        price_8hour: this.$props.price_8hour,

        selected_1hour: {},
        selected_2hour: {},
        selected_3hour: {},
        selected_4hour: {},
        selected_5hour: {},
        selected_6hour: {},
        selected_7hour: {},
        selected_8hour: {},
      },
    };
  },
  mounted() {},
  methods: {
    onFormChange(id, type) {
      // get status
      if (type == "times_1hour") {
        if (this.row.times_1hour[id]["status"]) {
          this.row.times_1hour[id]["status"] = false;
        } else {
          this.row.times_1hour[id]["status"] = true;
        }
      } else if (type == "times_2hour") {
        if (this.row.times_2hour[id]["status"]) {
          this.row.times_2hour[id]["status"] = false;
        } else {
          this.row.times_2hour[id]["status"] = true;
        }
      } else if (type == "times_3hour") {
        if (this.row.times_3hour[id]["status"]) {
          this.row.times_3hour[id]["status"] = false;
        } else {
          this.row.times_3hour[id]["status"] = true;
        }
      } else if (type == "times_4hour") {
        if (this.row.times_4hour[id]["status"]) {
          this.row.times_4hour[id]["status"] = false;
        } else {
          this.row.times_4hour[id]["status"] = true;
        }
      } else if (type == "times_5hour") {
        if (this.row.times_5hour[id]["status"]) {
          this.row.times_5hour[id]["status"] = false;
        } else {
          this.row.times_5hour[id]["status"] = true;
        }
      } else if (type == "times_6hour") {
        if (this.row.times_6hour[id]["status"]) {
          this.row.times_6hour[id]["status"] = false;
        } else {
          this.row.times_6hour[id]["status"] = true;
        }
      } else if (type == "times_7hour") {
        if (this.row.times_7hour[id]["status"]) {
          this.row.times_7hour[id]["status"] = false;
        } else {
          this.row.times_7hour[id]["status"] = true;
        }
      } else if (type == "times_8hour") {
        if (this.row.times_8hour[id]["status"]) {
          this.row.times_8hour[id]["status"] = false;
        } else {
          this.row.times_8hour[id]["status"] = true;
        }
      }

      for (let a = 0; a < this.row.times_1hour.length; a++) {
        this.row.selected_1hour[a] = {
          value: this.row.times_1hour[a]["value"],
          status: this.row.times_1hour[a]["status"],
        };
      }
      for (let b = 0; b < this.row.times_2hour.length; b++) {
        this.row.selected_2hour[b] = {
          value: this.row.times_2hour[b]["value"],
          status: this.row.times_2hour[b]["status"],
        };
      }
      for (let c = 0; c < this.row.times_3hour.length; c++) {
        this.row.selected_3hour[c] = {
          value: this.row.times_3hour[c]["value"],
          status: this.row.times_3hour[c]["status"],
        };
      }
      for (let d = 0; d < this.row.times_4hour.length; d++) {
        this.row.selected_4hour[d] = {
          value: this.row.times_4hour[d]["value"],
          status: this.row.times_4hour[d]["status"],
        };
      }
      for (let e = 0; e < this.row.times_5hour.length; e++) {
        this.row.selected_5hour[e] = {
          value: this.row.times_5hour[e]["value"],
          status: this.row.times_5hour[e]["status"],
        };
      }
      for (let f = 0; f < this.row.times_6hour.length; f++) {
        this.row.selected_6hour[f] = {
          value: this.row.times_6hour[f]["value"],
          status: this.row.times_6hour[f]["status"],
        };
      }
      for (let g = 0; g < this.row.times_7hour.length; g++) {
        this.row.selected_7hour[g] = {
          value: this.row.times_7hour[g]["value"],
          status: this.row.times_7hour[g]["status"],
        };
      }
      for (let h = 0; h < this.row.times_8hour.length; h++) {
        this.row.selected_8hour[h] = {
          value: this.row.times_8hour[h]["value"],
          status: this.row.times_8hour[h]["status"],
        };
      }

      const data = {
        times_1hour: this.row.selected_1hour,
        times_2hour: this.row.selected_2hour,
        times_3hour: this.row.selected_3hour,
        times_4hour: this.row.selected_4hour,
        times_5hour: this.row.selected_5hour,
        times_6hour: this.row.selected_6hour,
        times_7hour: this.row.selected_7hour,
        times_8hour: this.row.selected_8hour,

        price_1hour: this.row.price_1hour,
        price_2hour: this.row.price_2hour,
        price_3hour: this.row.price_3hour,
        price_4hour: this.row.price_4hour,
        price_5hour: this.row.price_5hour,
        price_6hour: this.row.price_6hour,
        price_7hour: this.row.price_7hour,
        price_8hour: this.row.price_8hour,
      };
      this.$emit("timesChange", data);
    },
  },
};
</script>
  